<template>
	<div class="index" id="agentTitle">
		<!-- <Imgbox src="//cdn.zhiyileiju.cn/41de49df3d7c95af0ba5ed.jpeg" /> -->
		<!-- <Imgbox src="//cdn.zhiyileiju.cn/941632814130_.pic_hd.jpg" :gossipImg="true"/> -->
		<Imgbox src="//cdn.zhiyileiju.cn/WechatIMG11123.jpeg" :gossipImg="true" :showluopan="!ishc" />
		<!-- <Imgbox src="//cdn.zhiyileiju.cn/1131632910373_.pic_hd.jpg" :gossipImg="true"/> -->
		<Card class="formcard">
	
			<van-form @submit="onSubmit" validate-trigger="onSubmit" @failed="valifail" class="forms_box">
				<!-- <h1 class="from_title_h1">填写信息</h1> -->
				<h1 class="from_title_h1"><img src="//cdn.zhiyileiju.cn/1141632910984_.pic_hd.jpg" alt=""></h1>
				<van-field v-model="Form.username" size="mid" name="username" placeholder="请输入宝宝姓氏"
					:rules="[{ validator }]" style="background: rgba(255, 255, 255, 0)">
					<template #label>
						<div class="labelclass">
							姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;氏
						</div>
					</template>
				</van-field>
				<van-field size="mid" name="status" v-if="false">
					<template #label>
						<div class="labelclass">出生状态</div>
					</template>
					<template #input>
						<van-radio-group v-model="status" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">已出生</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">未出生</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field size="mid" name="sex" style="background: rgba(255, 255, 255, 0)">
					<template #label>
						<div class="labelclass">
							性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
						</div>
					</template>
					<template #input>
						<van-radio-group v-model="Form.sex" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">男</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">女</van-radio>
							<van-radio name="3" icon-size="18" v-if="status == '2'" checked-color="#B95353">未知
							</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field  name="borth" size="mid" v-model="boyStr" :rules="[{ required: true}]"  style="background: rgba(255, 255, 255, 0)">
					<template #label>
						<div class="labelclass ">出生时间</div>
					</template>
					<template #input>
						<span @click="pickdata">{{boyStr ? boyStr : label }}</span>
					</template>
				</van-field>
				<van-field size="mid" v-model="Form.bit" type="text" name="bit" label="bit" placeholder="如字辈或喜用字" v-if="false">
					<template #label>
						<div class="lastlabel">定字<span>(可不填)</span></div>
					</template>
					<template #extra>
						<div @click="showBitPicker">{{ bittype }}</div>
					</template>
				</van-field>
				<div   class="tip center_position_text_box">-仅需9 s 取个好名字 陪伴宝宝一辈子-</div>
				<!-- 表单提交 -->
				<div class="center center_position_box">
					<van-button class="subbutton" size="large" color="#BD1721" type="info"  round
						@click="payclick('v1_index_top_form')" native-type="submit">
						<span style="color:#FEECC1">点击获取200个吉祥美名</span> </van-button>
				</div>

				<div v-if="needxieyi" class="center tip" >
					<van-checkbox v-model="checked" icon-size="16px" shape="square" checked-color="#b95353" style="margin-right:5px"></van-checkbox>
					本人同意领取并阅读，<span @click="lookrules = true" style="text-decoration: underline; color:#1122aa">个人信息保护政策</span>
				</div>

				<div  class="center tip" v-if="false"> 
					已为<span class="color-red" style="font-weight:bold">&nbsp;6,371,918&nbsp;</span>新生儿起名，好评满意率达99.8％
				</div>


				<div  class="actionScroll" v-if="false">
					<vue-seamless-scroll :data="listData" :class-option="defaultOption" class="warp">
						<ul class="scrollbox">
							<li class="scrollitem" v-for="(item, index) in listData" :key="index">
								<span class="title1" v-text="item.time_unit"></span>
								<span class="region123" style="width: 40px;" v-text="item.city"></span>
								<span class="name1" v-text="item.uname"></span>
								<span class="date" v-text="item.action"></span>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</van-form>
		</Card>

		<Card class="img" style="marginTop:104px;">
			<Imgbox src="//cdn.zhiyileiju.cn/511632645919_.pic_hd.jpg"></Imgbox>
		</Card>
		<Card class="img" >
			<Imgbox src="//cdn.zhiyileiju.cn/521632645924_.pic_hd.jpg"></Imgbox>
		</Card>
		<Card class="img" >
			<Imgbox src="//cdn.zhiyileiju.cn/2121634809899_.pic_hd.jpg"></Imgbox>
		</Card>
		<Card class="img" >
			<Imgbox src="//cdn.zhiyileiju.cn/701632727368_.pic_hd.jpg"></Imgbox>
		</Card>


		<!-- 九大维度 -->
		<template v-if="showCopyright">
			<Card class="img" >
				<Imgbox src="//cdn.zhiyileiju.cn/WechatIMG66.jpeg"></Imgbox>
			</Card>
		</template>

		<!-- 重要性 -->
		<Card v-if="showCopyright" class="img">
			<Imgbox src="https://cdn.zhiyileiju.cn/WechatIMG67.jpeg"></Imgbox>
		</Card>


		<Card class="formcard" style="margin-top:15px">
	
			<van-form @submit="onSubmit" validate-trigger="onSubmit" @failed="valifail" class="forms_box">
				<!-- <h1 class="from_title_h1">填写信息</h1> -->
				<h1 class="from_title_h1"><img src="//cdn.zhiyileiju.cn/1141632910984_.pic_hd.jpg" alt=""></h1>
				<van-field v-model="Form.username" size="mid" name="username" placeholder="请输入宝宝姓氏"
					:rules="[{ validator }]" style="background: rgba(255, 255, 255, 0)">
					<template #label>
						<div class="labelclass">
							姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;氏
						</div>
					</template>
				</van-field>
				<van-field size="mid" name="status" v-if="false">
					<template #label>
						<div class="labelclass">出生状态</div>
					</template>
					<template #input>
						<van-radio-group v-model="status" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">已出生</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">未出生</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field size="mid" name="sex" style="background: rgba(255, 255, 255, 0)">
					<template #label>
						<div class="labelclass">
							性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
						</div>
					</template>
					<template #input>
						<van-radio-group v-model="Form.sex" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">男</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">女</van-radio>
							<van-radio name="3" icon-size="18" v-if="status == '2'" checked-color="#B95353">未知
							</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field  name="borth" size="mid" v-model="boyStr" :rules="[{ required: true}]"  style="background: rgba(255, 255, 255, 0)">
					<template #label>
						<div class="labelclass ">出生时间</div>
					</template>
					<template #input>
						<span @click="pickdata">{{boyStr ? boyStr : label }}</span>
					</template>
				</van-field>
				<van-field size="mid" v-model="Form.bit" type="text" name="bit" label="bit" placeholder="如字辈或喜用字" v-if="false">
					<template #label>
						<div class="lastlabel">定字<span>(可不填)</span></div>
					</template>
					<template #extra>
						<div @click="showBitPicker">{{ bittype }}</div>
					</template>
				</van-field>
				<div   class="tip center_position_text_box">-仅需9 s 取个好名字 陪伴宝宝一辈子-</div>
				<!-- 表单提交 -->
				<div class="center center_position_box">
					<van-button class="subbutton" size="large" color="#BD1721" type="info"  round
						@click="payclick('v1_index_top_form')" native-type="submit">
						<span style="color:#FEECC1">点击获取200个吉祥美名</span> </van-button>
				</div>

				<div v-if="needxieyi" class="center tip" >
					<van-checkbox v-model="checked" icon-size="16px" shape="square" checked-color="#b95353" style="margin-right:5px"></van-checkbox>
					本人同意领取并阅读，<span @click="lookrules = true" style="text-decoration: underline; color:#1122aa">个人信息保护政策</span>
				</div>

				<div  class="center tip" v-if="false"> 
					已为<span class="color-red" style="font-weight:bold">&nbsp;6,371,918&nbsp;</span>新生儿起名，好评满意率达99.8％
				</div>


				<div  class="actionScroll" v-if="false">
					<vue-seamless-scroll :data="listData" :class-option="defaultOption" class="warp">
						<ul class="scrollbox">
							<li class="scrollitem" v-for="(item, index) in listData" :key="index">
								<span class="title1" v-text="item.time_unit"></span>
								<span class="region123" style="width: 40px;" v-text="item.city"></span>
								<span class="name1" v-text="item.uname"></span>
								<span class="date" v-text="item.action"></span>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</van-form>
		</Card>

		<div v-if="zylj"  class="webfooter" style="margin-top:80px;height:160px;font-size:10px;color:#323232;">
			<!-- <div class="synopsis">公司简介：杭州智以类聚科技有限公司是一家专业服务于移动互联网的高新技术企业，集人工智能理论与算法软件开发、一体化整体解决方案、大数据分析等为一体的综合服务商。</div> -->
			<div>版权所有 ©2018-2021 杭州智以类聚科技有限公司</div>
			<div>地址：浙江省杭州市余杭区五常街道期望铭座B座305</div>
			<div>电话：18975441215</div>
		</div>

		<div v-else  class="webfooter" style="margin-top:80px;height:160px;font-size:10px;color:#323232;">
			<div>版权所有©2018-2021 杭州智以类聚科技有限公司</div>
			<div>公司地址：浙江省杭州市余杭区五常街道期望铭座B座305</div>
			<div>电话：18975441215</div>
		</div>

		<!-- <div  class="center navboxs">
				 <router-link class="navite" to="/index">首页</router-link>
				 <router-link class="navite" to="/about">关于我们</router-link>
		</div> -->


		<van-button :class="fixedbtn == true ? 'isFixed' : ''" size="large" color="#BD1721" type="info"
			native-type="submit" @click="bottombtnclick">
			立即获取美名
		</van-button>

		

		<!-- 日期选择器 -->
		<rui-datepick :date="currentDate" v-model="boyShow" @confirm="boyConfirm" />
		<!-- 定字选择器 -->
		<van-popup v-model="showPicker" position="bottom">
			<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onBitConfirm" />
		</van-popup>

		<!-- 红包弹窗 -->
		<van-popup class="redpacket" v-model="showredPacket">
			<div class="redpacketbox">
				<div class="center redtitile">恭喜获得红包！</div>
				<div class="center dicsountnum" style="line-height:1em;align-items: flex-end;font-size:40px;color:red;font-weight:500;margin-top:25px">
					￥{{price.discount_amount}}
					<span style="margin-left:5px;line-height:1.5em;font-size:18px">元</span>
				</div>
				<div class="center" style="margin-top:15px;font-size:14px;color:#C37D3F">使用可半价查看200个甄选美名</div>
				<div class="countbox center">
					<van-count-down :time="counttime" millisecond>
						<template #default="timeData">
							<span class="block">{{ timeData.hours }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.minutes }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.seconds }}</span>
							<span class="colon">:</span>
							<span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
						</template>	
					</van-count-down>
					<span style="color:#fff;font-size:12px;margin-left:10px;font-weight: 500;">后失效</span>
				</div>
				<van-button class="redbtn" @click="buy" round>点击立即使用</van-button>
			</div>	
		</van-popup>

		<!-- 订单确认弹框 -->
		<van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
            <div class="wrapper center" @click.stop>
                <div class="blockbx">
                    <div class="tit">请确认支付是否完成</div>
                    <div class="payed">
                        <van-button round block @click="Dcheckorder" class="Dcheckorder" type="primary">付款成功，点击查看名字</van-button>
                    </div>
                    <div class="unpay" @click="shopPayConfirm = false">
                        未支付，我再考虑一下
                    </div>
                </div>
            </div>
        </van-overlay>

		<!-- 隐私协议 -->
		<van-popup v-model="lookrules" style="width:90%;max-height:500px;" closeable>
			<div style="padding:20px;padding-top:20px">
				<h1 class="center">用户隐私协议</h1>
				尊敬的用户，欢迎阅读本协议：<br/><br/>

{{CopyRight}}依据本协议的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的{{CopyRight}}服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。<br/><br/>

用户在申请{{CopyRight}}服务过程中，需要填写一些必要的个人信息，为了更好的为用户服务，请保证提供的这些个人信息的真实、准确、合法、有效并注意及时更新。若因填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。<br/><br/>

保护用户个人信息是{{CopyRight}}的一项基本原则，{{CopyRight}}运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。未经用户许可{{CopyRight}}不会向第三方（{{CopyRight}}控股或关联、运营合作单位除外）公开、透露用户个人信息，但由于政府要求、法律政策需要等原因除外。<br/><br/>

在用户发送信息的过程中和本网站收到信息后，本网站将遵守行业通用的标准来保护用户的私人信息。但是任何通过因特网发送的信息或电子版本的存储方式都无法确保100%的安全性。因此，本网站会尽力使用商业上可接受的方式来保护用户的个人信息，但不对用户信息的安全作任何担保。<br/><br/>

此外，您已知悉并同意：在现行法律法规允许的范围内，{{CopyRight}}科技可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用{{CopyRight}}科技服务或产品的情况所认为您可能会感兴趣的信息。<br/><br/>

本网站有权在必要时修改服务条例，本网站的服务条例一旦发生变动，将会在本网站的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。<br/><br/>

如果您还有其他问题和建议，可以通过电子邮件673681507@qq.com或者电话17620368211联系我们。

{{CopyRight}}保留对本协议的最终解释权。
		<van-button class="redbtn" @click="lookrules=false" round>关闭</van-button>
</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		RadioGroup,
		Radio,
		Form,
		Field,
		Toast,
		Picker,
		Popup,
		Tag,
		CountDown,
		Overlay,
		Checkbox
	} from "vant";

	import Imgbox from "../components/ImageBox.vue";
	import Card from "../components/Card.vue";
	import ruiDatepick from "../components/datepick/index.vue";
	import countTo from "vue-count-to";
	import Comment from "../components/Comment.vue";
	import vueSeamlessScroll from "vue-seamless-scroll";
	import router from "../router";
	import report from "../utils/stat";

	import axios from "../utils/request";

	export default {
		name: "Home",
		components: {
			Imgbox,
			Card,
			vanForm: Form,
			vanField: Field,
			vanRadioGroup: RadioGroup,
			vanRadio: Radio,
			ruiDatepick,
			vanPicker: Picker,
			vanPopup: Popup,
			countTo,
			vanTag: Tag,
			vanOverlay: Overlay,
			Comment,
			vueSeamlessScroll,
			vanCountDown:CountDown,
			vanCheckbox:Checkbox
		},
		computed: {
			defaultOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: this.listData.length,
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: false, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
			dialogwidth() {
            	return window.innerWidth * 0.9;
        	},
			showCopyright() {
				let from = this.$route.query.from;
				let allowfrom = ['a1','a2','a6','a7', 'ab1','a9', 'b1','c1','d1','ac1','k1','k2','ad1','k3','f1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			needxieyi(){
				let from = this.$route.query.from;
				let allowfrom = ['a1','a2', 'a6', 'a7','ab1','a9','ad1','ac1','k1','k2','k3','d1','d2'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
        	},
			CopyRight(){
				let from = this.$route.query.from;
				if(['k1','k2','k3','k4','k7','k5','k6'].indexOf(from) > -1){
					return "上海容铭信息科技有限公司"
				}else if(['d1','d2','k3','k4','d5','d7','d9','d11'].indexOf(from)){
					return '武汉道森梯媒文化'
				}else{
					return "亮亮文化";
				}
				
			},
			ishc(){
				let from = this.$route.query.from;
				let allowfrom = ['h1','h2',];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			zylj(){
				let from = this.$route.query.from;
					let allowfrom = ['b1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
		},
		watch: {
			status(a, b) { //深度监听，可监听到对象、数组的变化
				if (a == 2) {
					this.Form.sex = "3"
					this.label = '请选择预产期'
				} else {
					this.Form.sex = "1"
					this.label = '请选择出生时间'
				}
			}
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
			window.localStorage.setItem('fromk', this.$route.query.from);
			report("v2_index");
		},
		data() {
			let currentDate = new Date();
			let orderid = sessionStorage.getItem('orderid')
			let paysuccess = sessionStorage.getItem('paysuccess')
			return {
				showredPacket: (orderid && !paysuccess) ? true :false,
				shopPayConfirm:false,
				lookrules:false,
				checked:true,
				radio: 1,
				boyShow: false,
				showPicker: false,
				bottom_btn_clicked: false,
				counttime:10*60*1000,
				comments: [1, 2, 3, 4, 5, 6, 7, 8, 98, 10],
				label: "请选择出生时间",
				currentDate: currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate(),
				listData: [{
					action: "正在填写出生日期",
					city: "怒江傈僳族自治州",
					created_at: "2021-07-05 12:06:50",
					id: 1,
					time_unit: "56秒前",
					uname: "矮小扯小蚂蚁",
					updated_at: "2021-07-05 12:06:50",
				}, ],
				bittype: "中间字",
				boyStr: "",
				columns: ["中间字", "尾部字"],
				startVal: 635000,
				endVal: 6371918,
				fixedbtn: false,
				status: "1",
				Form: {
					username: "",
					status: "1",
					sex: "1",
					bit: "",
				},
				price:[],
				orderinfo:{}
			};
		},
		async created() {
			let data = await this.getactives();
			this.listData = data.data;
			if (this.$route.query.uniqueKey) {
				window.localStorage.setItem("uniqueKey", this.$route.query.uniqueKey)
			}
			this.getPrice();
		},
		destroyed() {
			this.timer && clearTimeout(this.timer);
		},
		methods: {
			pickdata() {
				this.boyShow = true;
			},
			async getPrice(){
				let price = await axios.get(`/getSalesPrice?fromk=${this.$route.query.from}`);
				this.price = price.data.data[0] || [];
			},
			async buy(){
				report("v2_redpacket_click");
				await this.confirmOrder();
				let t = this;
				setTimeout(async function(){
					await t.checkorder(sessionStorage.getItem('orderid'), 2);
				},2000)
				location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat/${sessionStorage.getItem('orderid')}`;
				this.showredPacket = false;
				this.shopPayConfirm = true;
			},
			async confirmOrder() {
				try {
					let aa = await axios.post("/order/confirm", {
						order_id: sessionStorage.getItem('orderid'),
						paytype: 2,
						discount_id: 1
					});
				} catch (err) {}
			},
			checkorder(id, type) {
				this.timer = setInterval(async () => {
					try {
						let data = await axios.post("/payment/query", {
							paytype: type,
							order_id: id,
						});
						if (data.data.data.is_pay) {
							router.replace({
								name: "paysuccess",
								params: { id, type: data.data.data.type },
							}).catch(() => {});
						}
					} catch (err) {}
				}, 5000);
			},
		    async mcheckorder() {
				let data = await axios.post("/payment/query", {
					paytype: 2,
					order_id: sessionStorage.getItem('orderid'),
				});
				if (data.data.data.is_pay) {
					router.replace({
						name: "paysuccess",
						params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype },
					}).catch(() => {});
				} else {
					Toast.fail("当前订单未支付");
				}
			},
			async Dcheckorder() {
				try {
					this.mcheckorder();
				} catch (err) {
					Toast.fail("当前订单未支付");
				}
			},
			async getactives() {
				console.log('123213213---')
				let data = await axios.post("/ekaf", {
					type: 3,
					perPage: 10,
					page: 1,
				});
				return data.data;
			},
			boyConfirm(data) {
				this.birthdata = data;
				this.boyStr = data.thisStr;
			},
			valifail(errinfo) {
				let errors = errinfo.errors[0]
				if (errors.name == "username") {
					Toast('姓氏填写不正确')
				} else {
					Toast('出生日期填写不正确')
				}
			},
			showBitPicker() {
				this.showPicker = true;
			},
			validator(val) {
				if (val.length > 0 && val.length < 3) {
					return true;
				} else {
					return false;
				}
			},
			async onSubmit() {
				let birth;
				// if(this.Form.username.length >1){
				//   Toast.fail("当前不支持双姓取名")
				//   return;
				// }
				if (this.birthdata.hour) {
					birth =
						this.birthdata.year +
						"-" +
						this.birthdata.month +
						"-" +
						this.birthdata.day +
						" " +
						this.birthdata.hour +
						":00:00";
				} else {
					birth =
						this.birthdata.year +
						"-" +
						this.birthdata.month +
						"-" +
						this.birthdata.day +
						" 00:00:00";
				}
				let postdata = {
					birth_state: this.status,
					xname: this.Form.username,
					sex: this.Form.sex,
					pub_birth_date: birth,
					order_type: 1,
					fromk: this.$route.query.from || 10,
					needwdtype: this.bittype == "中间字" ? 1 : 2,
				};

				if (this.Form.bit) postdata.need_word = this.Form.bit;

				if (this.status == 1) {
					postdata.pub_birth_date = birth;
				} else {
					postdata.pub_prebirth_date = birth;
				}
				Toast.loading({
					message: '请稍后...',
					forbidClick: true,
				});
				let order = await axios.post("/addOrder", postdata);
				Toast.clear();
				router.push({
					name: "pay",
					params: {
						id: order.data.data.id,
						type: 10
					},
				}).catch(() => {});
			},
			onBitConfirm(value) {
				this.bittype = value;
				this.Form.bittype = value == "中间字" ? 1 : 2;
				this.showPicker = false;
			},
			handleScroll() {
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 300) {
					this.fixedbtn = true;
				} else {
					this.fixedbtn = false;
				}
			},
			bottombtnclick() {
				document
					.getElementById("agentTitle")
					.scrollIntoView({
						block: "start",
						behavior: "smooth"
					});
				this.bottom_btn_clicked = true;
				report('v2_index_b_btn');
			},

			payclick(type) {
				if (type == 'v2_index_top_form' && this.bottom_btn_clicked) {
					report('v2_index_b_top_form');
					return;
				}
				report(type);
			}
		},
	};
</script>

<style scoped>
@import "../style/main.css";
.wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}


.forms_box{
	position: relative;
	/* border: 1px #BD1721 solid; */
	padding-bottom: 18px;
	padding-top: 1px;
	background: url('http://cdn.zhiyileiju.cn/1091632906310_.pic_hd.jpg') round;
	/* background: url('http://cdn.zhiyileiju.cn/1101632908663_.pic_hd.jpg') round; */
	 background-size: cover;
}
.forms_box .from_title_h1{
	width: 76%;
	padding: 0;
	margin: 0 auto;
	color: #BD1721;
	font-family: 'SourceHanSerifSC-Bold';
	font-size: 2em;
	text-align: center;
	background: url('http://cdn.zhiyileiju.cn/1081632906309_.pic_hd.jpg') round;
}
.forms_box .from_title_h1 img {
	width: 35%;
}
.center_position_text_box{
	position: absolute;
	left: 0;
	right: 0;
	bottom: -40px;
}
.center_position_box{
	position: absolute;
	left: 0;
	right: 0;
	bottom: -80px;
}

.img_border_box{
	border: 1px solid #BD1721;
	padding-top: 50px;
	padding-bottom: 20px;
}
.img_border_box h1{
	font-family:'MicrosoftYaHei';
	text-align: center;
	font-size: 24px;
	font-weight: 400;
	color: #BD1721;
}
.img_border_box .h1{
	font-family:'MicrosoftYaHei';
	text-align: center;
	font-size: 24px;;
	color: #323232;
}
.img_border_box .p{
	font-family:'MicrosoftYaHei';
	text-align: center;
	font-size: 16px;
	color: #979797;
}
.img_border_box p{
	font-family:'MicrosoftYaHei';
	text-align: center;
	font-size: 16px;
	color: #323232;
}
.img_border_box_padding{
	padding:50px 20px;
	overflow: hidden;
	text-align: center;
}
.semicircle_box{
	
	text-align: center;
	height: 34px;
	left: 0;
	right: 0;
	border:solid 1px #BD1721;
	border-radius: 34px;
}
.img_border_box_padding span{
	position: relative;
	display: inline-block;
	width: 0;
	height: 0;
	border:18px solid ;
	border-color: #BD1721 #979797 #979797 #BD1721;
}
.img_border_box .left_semicircle_box{
	/* display: inline-block; */
	position: absolute;
	left: -120px;
    top: -18px;
	width: 100px;
	height: 34px;
	border:solid 1px #BD1721;
	background: #BD1721;
	border-top-left-radius: 17px;
	border-bottom-left-radius:17px
}
.left_semicircle_box span{
	position: absolute;
	bottom: -1px;
	right: -37px;
	display: inline-block;
	width: 0;
	height: 0;
	border:18px solid ;
	border-color: #BD1721 transparent transparent #BD1721;
}
.img_border_box .right_semicircle_box_{
	/* display: inline-block; */
	float: right;
	position: absolute;
	right: -120px;
    top: -18px;
	width: 100px;
	height: 34px;
	border:solid 1px #979797;
	background: #979797;
	border-top-right-radius: 17px;
	border-bottom-right-radius:17px;
	margin-left: 36px;
}
.right_semicircle_box_ span{
	position: absolute;
	bottom: -1px;
	left: -37px;
	display: inline-block;
	width: 0;
	height: 0;
	border:18px solid ;
	border-color:transparent  #979797 #979797 transparent;
}

.box__{
	overflow: hidden;
}
.box__ p{
	text-align: left;
	font-size: 14px;
	margin: 0;
	padding-left:5px;
}
.text_box{
	width: 100%;
	/* height: 150px; */
	border: 1px solid #BD1721;
}
.img_border_box_padding .left_text_box{
	float: left;
	width: 44%;
	/* height: 100px; */
	border: 1px solid #BD1721;
	padding-top: 5px;
	
	
}
.img_border_box_padding .right_text_box{
	float: right;
	width: 44%;
	height: 100px;
	border: 1px solid #BD1721;
}
.left_center_box{
	background: #BD1721;
	height: 40px;
	line-height: 40px;
	color: white;
	text-align: left;
	padding-left: 5px;
}



.blockbx {
    width: 80%;
    background-color: white;
    border-radius: 5px;
}

.Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}

.blockbx .tit {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #eeeeee;
}

.blockbx .payed {
    color: #b91e1e;
    font-size: 18px;
    line-height: 80px;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
}

.unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
}


	.redpacket.van-popup {
		background: none;
	}

	.redpacketbox{
		width: 75vw;
		height: 108vw;
		max-width: 300px;
		max-height: 432px;
		padding-top: 60px;
		box-sizing: border-box;
		background: url('https://cdn.zhiyileiju.cn/WechatIMG82.png') no-repeat center;
		background-size: 100%;
	}

	.redbtn{
		width: 40vw;
		height: 45px;
		display: block;
		margin: 0 auto;
		margin-top: 20px;
		background: #FEC995;
		border: none;
		color: #C41713;
		font-size: 18px;
		font-weight: 500;
	}

	.redtitile{
		color: #E38000;
		font-weight: bold;
		font-size: 18px;
	}

	.countbox{
		margin-top:80px;
	}

	.colon {
        display: inline-block;
        margin: 0 4px;
        color: #fff;
      }
      .block {
        display: inline-block;
        width: 20px;
		height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #E51D2C;
      }
	.webfooter {
		height: 120px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		line-height: 2em;
		font-size: 14px;
		padding-top: 20px;
		/* background: url("//cdn.zhiyileiju.cn/kexin.png") no-repeat top;
		background-size: 250px;
		background-color: #fff; */
	}

	.navboxs{
		width: 100%;
		max-width: 640px;
		margin: 0 auto;
		background: #b95353;
		color: #fff;
		position: fixed;
		bottom: 0;
		height: 60px;
	}

	.navite{
		display: inline-block;
		width:50%;
		color: #fff;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		border-right: 1px solid #fff;
	}
	.navite:last-child{
		border-right: none;
	}

	#agentTitle {
		scroll-behavior: smooth;
	}



	.van-field__label {
		height: 2em;
		line-height: 2em;
	}

	.van-field__control {
		height: 2em;
		line-height: 2em;
	}

	.lastlabel {
		color: rgb(50, 50, 50);
	}

	.lastlabel span {
		font-size: 9px;
		color: #333333;
		font-weight: normal;
	}

	.van-cell--mid {
		padding: 12px 16px;
	}

	.subbutton {
		color: #333333;
		width: 280px;
		font-size: 18px;
		animation: scaleDrew 1.5s ease-in-out infinite;
	}


	.van-cell {
		font-size: 16px;
	}

	.boxheader {
		font-size: 18px;
		font-weight: bold;
		color: #b95353;
	}

	.subheader {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
	}

	.gdname {
		color: #b95353;
		margin-right: 25px;
	}

	.bdname {
		color: #979797;
		margin-left: 25px;
	}

	.subheader .subimg {
		width: 120px;
	}

	.vsli {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}

	.vsli p {
		margin: 0;
		padding: 0;
	}

	.vsli .top1,
	.vsli .top2 {
		margin-top: 10px;
		line-height: 2.2em;
		padding-left: 8px;
	}

	.vsli .subtop1,
	.vsli .subtop2 {
		padding-left: 8px;
	}

	.vsli .subtop1 {
		color: #b95353;
	}


	.vsitem {
		font-size: 9px;
		font-weight: bold;
		width: 37vw;
		height: 130px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.vsitem.gditem {
		border: 1px solid #b95353;
	}

	.vsitem.bditem {
		border: 1px solid #979797;
		color: #979797;
	}

	.vsitem .sub1footer,
	.vsitem .sub2footer {
		display: flex;
		align-items: center;
		height: 36px;
		padding-left: 8px;
	}

	.sub1footer {
		background: #b95353;
		color: white;
	}

	.sub2footer {
		background: #979797;
		color: white;
	}

	.vstype {
		width: 20px;
		height: 20px;
		font-size: 12px;
		font-weight: bold;
		background: #b95353;
		color: white;
		margin: 0 7px;
	}
</style>
