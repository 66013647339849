<template>
	<div class="warp" :class="customstyle">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'Card',
		props: {
			customstyle: String
		},
		methods: {

		}
	}
</script>

<style>
	.warp {
		border-radius: 5px;
		/* background-color: white; */
	}
</style>
