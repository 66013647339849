import Vue from 'vue'
import App from './App.vue'
import router from './router'

// import "amfe-flexible"; 
import Card from "./components/Card.vue";
import Imgbox from "./components/ImageBox.vue";
import {
  Col,
  Row,
  Button,
  Dialog,
} from "vant";
import { Collapse, CollapseItem } from 'vant';


Vue.component('Card', Card)
Vue.component('Imgbox', Imgbox)
Vue.component('vanCol', Col)
Vue.component('vanRow', Row)
Vue.component('vanButton', Button)
Vue.component('vanCollapse', Collapse)
Vue.component('vanCollapseItem', CollapseItem)

Vue.use(Dialog);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
