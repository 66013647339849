import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
   path: '/',
   redirect: '/index',
  },
  {
    path: '/pay/:id/:type',
    name: 'pay',
    component: () => import('../views/Pay.vue')
  },
  {
    path:'/pay/success/:id/:type',
    name:"paysuccess",
    component:() => import('../views/success.vue'),
  },
  {
    path:'/result/:id',
    name:"payresult",
    meta: { 
      keepAlive: true // 需要缓存 
    },
    component:() => import('../views/result.vue'),
  },
  {
    path:'/result/detail/:id/:nameid/:type',
    name:"resultdetail",
    component:() => import('../views/resultdetail.vue'),
  },
  {
    path:'/teacher/:id',
    name:'teacher',
    // component:() => import("../views/teacher.vue"),//弃用
    component:() => import("../views/teacherv2.vue"),
  },
  // {
  //   path:'/teacherv2/:id',
  //   name:'teacher',
  //   component:() => import("../views/teacherv2.vue"),
  // },
 
  {
  	path:"/teacher/lookpre/:id/:teacherid",
  	name:"testresultpre",
  	component:()=>import("../views/testresultpre.vue")
  },
  {
    path:'/teacher/success/:id',
    name:'teachersuccess',
    component:() => import("../views/msuccess.vue"),
  },
  {
    path:'/about',
    name:'about',
    component:() => import("../views/about.vue"),
  },
  {
    path:"/plusname/:resultid/:id",
    name:"plusname",
    component:() => import("../views/plusnameResult.vue"),
    meta: { 
      keepAlive: true // 需要缓存 
    },
  },
  {
    path:'/buyplusname/:id',
    name:"buyplusname",
    component:()=>import("../views/plusnamebuy.vue")
  },{
    path:'/aitestname',
    name:"aitestname",
    component:()=>import("../views/testname.vue")//测名页面
  },{
    path:'/aitestname/pay/:id/:type',
    name:"aitestnamepay",
    component:()=>import("../views/testnamePay.vue")
  },
  {
    path:'/aitestname/success/:id/:type',
    name:"aitestsuccess",
    component:()=>import("../views/testpaysuccess.vue")
  },{
	path:"/testresult/:id/:type",
	name:"testresult",
	component:()=>import("../views/testresult.vue")
  }
]

const router = new VueRouter({
	mode:"history",
  routes
})

router.beforeEach((to, from, next) => {
  let query = {...to.query}

  let uniqueKey = window.localStorage.getItem('uniqueKey');
  if(uniqueKey){
    query.uniqueKey = uniqueKey
  }
  
  if (!to.query.from) {
    let fromk= window.localStorage.getItem('fromk')? window.localStorage.getItem('fromk') :10;
    query.from = to.query.from || from.query.from || fromk ;
    next({...to, query})  
  }else if(uniqueKey && !to.query.uniqueKey){
    next({...to, query})
  }else{
    next();
  }

})
export default router
