<template>
  <div class="box">
    <img src="//cdn.zhiyileiju.cn/901632798872_.pic_hd.jpg" alt="" class="gossip_img" v-if="gossipImg && showluopan">
    <img src="//cdn.zhiyileiju.cn/921632810720_.pic_hd.jpg" alt="" class="jump_up_and_down jump_up_and_down_1" v-if="gossipImg">
    <img src="//cdn.zhiyileiju.cn/911632810719_.pic_hd.jpg" alt="" class="jump_up_and_down jump_up_and_down_2" v-if="gossipImg">
    <img src="//cdn.zhiyileiju.cn/931632810720_.pic_hd.jpg" alt="" class="jump_up_and_down jump_up_and_down_3" v-if="gossipImg">
    <img @click="picclick" :src="src" />
  </div>
</template>

<script>
export default {
  name: "Img",
  props: {
    src: String,
    gossipImg:Boolean,
    showluopan:Boolean
  },
  methods: {
    picclick() {
      this.$emit("click");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.box img {
  width: 100%;
}
.box .gossip_img{
  position: absolute;
  top: 45%;
  left: -15.5%;
  width: 53%;
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  /* -webkit-animation: rotate 3s linear infinite;
  -moz-animation: rotate 3s linear infinite;
  -o-animation: rotate 3s linear infinite; */
  animation: rotate 10s linear infinite;
}
@-webkit-keyframes rotate{from{-webkit-transform: rotate(0deg)}
            to{-webkit-transform: rotate(360deg)}
        }
        @-moz-keyframes rotate{from{-moz-transform: rotate(0deg)}
            to{-moz-transform: rotate(359deg)}
        }
        @-o-keyframes rotate{from{-o-transform: rotate(0deg)}
            to{-o-transform: rotate(359deg)}
        }
        @keyframes rotate{from{transform: rotate(0deg)}
            to{transform: rotate(359deg)}
        }

.box .jump_up_and_down{
  position: absolute;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.box .jump_up_and_down_1{
  top: 35%;
  left: 16%;
  width: 22%;
  animation-name: example_1;
}
.box .jump_up_and_down_2{
  top: 51%;
  left: 37%;
  width: 14%;
  animation-name: example_2;
}
.box .jump_up_and_down_3{
  top: 69%;
  left: 34%;
  width: 15%;
  animation-name: example_3;
}

/* 动画代码 */
@keyframes example_1 {
  0%{top: 32%;}
  50% {top: 30%;}
  100% {top: 32%;}
}
@keyframes example_2 {
  0%{top: 51%;}
  50% {top: 49%;}
  100% {top: 51%;}
}
@keyframes example_3 {
  0%{top: 69%;}
  50% {top: 67%;}
  100% {top: 69%;}
}


</style>
